<script>
export default {
  name: 'about',

  data() {
    return {
      type: null,
    };
  },

  watch: {
    $route() {
      this.type = this.$route.meta.data;
    },
  },

  created() {
    this.type = this.$route.meta.data;
  },
};
</script>

<template>
  <div class="about">
    <h1>This is an {{ type }} page</h1>
  </div>
</template>
